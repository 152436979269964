import mapboxgl from 'mapbox-gl';
import { layout, paint } from './symbol';

class MapPostboxes {
  constructor(element) {
    const canvasContainer = element.querySelector('.m-map-postboxes__canvas');
    this.element = element;
    this.postBoxesData = null;
    this.postBoxesFeaturesData = null;
    const accessToken = 'pk.eyJ1Ijoid3ctdG9iaWFzd29sZiIsImEiOiJja2N5azd1N2cwN2p0Mnp2cHhtemVnZmdxIn0.v5heUd7FGFS2GG_cQcpyEA';
    const centerLng = 9.209197;
    const centerLat = 48.496034 - 0.04;
    const maxBounds = [centerLng - 0.5, centerLat - 0.3, centerLng + 0.5, centerLat + 0.3];
    mapboxgl.accessToken = accessToken;

    function loadMap() {
      this.map = new mapboxgl.Map({
        container: canvasContainer,
        style: 'mapbox://styles/ww-tobiaswolf/ckcyl9i401y1m1imnc51b8yo3', // stylesheet location
        center: [centerLng, centerLat], // starting position [lng, lat]
        zoom: 8, // starting zoom
        minZoom: 6,
        maxBounds,
        showZoom: true,
        locale: {
          'AttributionControl.ToggleAttribution': 'Kennzeichnung umschalten',
          'AttributionControl.MapFeedback': 'Rückmeldung zur Karte',
          'FullscreenControl.Enter': 'Vollbildschirm anzeigen',
          'FullscreenControl.Exit': 'Vollbildschirm verlassen',
          'GeolocateControl.FindMyLocation': 'Mein Standort finden',
          'GeolocateControl.LocationNotAvailable': 'Standort nicht verfügbar',
          'LogoControl.Title': 'Mapbox-Logo',
          'NavigationControl.ResetBearing': 'Ausrichtung auf Nordern zurücksetzen',
          'NavigationControl.ZoomIn': 'Vergrößern',
          'NavigationControl.ZoomOut': 'Verkleinern',
          'ScaleControl.Feet': 'ft',
          'ScaleControl.Meters': 'm',
          'ScaleControl.Kilometers': 'km',
          'ScaleControl.Miles': 'mi',
          'ScaleControl.NauticalMiles': 'nm',
        },

      });

      this.map.addControl(new mapboxgl.NavigationControl());
      this.map.addControl(new mapboxgl.GeolocateControl());

      this.map.on('load', () => {
        this.map.setLayoutProperty('country-label', 'text-field', [
          'get',
          'name_de',
        ]);

        (async () => {
          const postBoxesData = await this.postBoxes;
          const postBoxesFeatures = postBoxesData.features.filter((item) => item.stampsale === false);
          const stampSalesFeatures = postBoxesData.features.filter((item) => item.stampsale === true);

          const postBoxes = postBoxesData;
          const stampSales = postBoxesData;

          postBoxes.features = postBoxesFeatures;
          this.map.addSource('postBoxes', {
            type: 'geojson',
            data: postBoxes,
          });

          stampSales.features = stampSalesFeatures;
          this.map.addSource('stampSales', {
            type: 'geojson',
            data: stampSales,
          });

          this.map.loadImage('/assets/images/post-box--map.png', (error, image) => {
            if (error) throw error;
            if (!this.map.hasImage('post-box')) {
              this.map.addImage('post-box', image, {
                pixelRatio: 2,
              });
            }
            const postBoxesLayout = layout;
            postBoxesLayout['icon-image'] = 'post-box';

            this.map.addLayer({
              id: 'postBoxes',
              type: 'symbol',
              source: 'postBoxes',
              layout: postBoxesLayout,
              paint,
            });
          });

          this.map.loadImage('/assets/images/stamp-sale--map.png', (error, image) => {
            if (error) throw error;
            if (!this.map.hasImage('stamp-sale')) {
              this.map.addImage('stamp-sale', image, {
                pixelRatio: 2,
              });
            }
            const stampSalesLayout = layout;
            stampSalesLayout['icon-image'] = 'stamp-sale';
            stampSalesLayout['symbol-sort-key'] = 0;

            this.map.addLayer({
              id: 'stampSales',
              type: 'symbol',
              source: 'stampSales',
              layout: stampSalesLayout,
              paint,
            });
          });

          if (window.location.hash.length > 1) {
            setTimeout(() => {
              this.flyToSearchResult();
            }, 1000);
          }
        })();

        this.map.on('click', 'postBoxes', (event) => {
          this.openPopup(event.features[0]);
        });
        this.map.on('click', 'stampSales', (event) => {
          this.openPopup(event.features[0]);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        this.map.on('mouseenter', 'postBoxes', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseenter', 'stampSales', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        this.map.on('mouseleave', 'postBoxes', () => {
          this.map.getCanvas().style.cursor = '';
        });
        this.map.on('mouseleave', 'stampSales', () => {
          this.map.getCanvas().style.cursor = '';
        });
      });

      this.map.on('wheel', (event) => {
        if (event.originalEvent.ctrlKey) {
          return;
        }

        if (event.originalEvent.metaKey) {
          return;
        }

        if (event.originalEvent.altKey) {
          return;
        }

        event.preventDefault();
      });

      window.addEventListener('hashchange', () => {
        this.flyToSearchResult();
      });
    }

    loadMap.bind(this)();
  }

  get postBoxes() {
    return new Promise((resolve) => {
      if (this.postBoxesData) {
        resolve(this.postBoxesData);
      } else {
        (async () => {
          const dataUrl = `${window.location.origin}/briefkaesten.json`;
          this.postBoxesData = await fetch(dataUrl, {
            cache: 'no-cache',
          }).then((response) => response.json());
          resolve(this.postBoxesData);
        })();
      }
    });
  }

  get postBoxesFeatures() {
    if (this.postBoxesFeaturesData) {
      return this.postBoxesFeaturesData;
    }
    const postBoxesFeatures = [...this.map.querySourceFeatures('postBoxes'), ...this.map.querySourceFeatures('stampSales')];
    if (postBoxesFeatures.length > 0) {
      this.postBoxesFeaturesData = postBoxesFeatures;
    }
    return postBoxesFeatures;
  }

  flyToSearchResult() {
    const {
      map,
    } = this;
    const id = window.location.hash.substr(1);
    if (id.length > 0) {
      if (this.openedPopup) {
        this.openedPopup.remove();
      }
      if (id.startsWith('postbox.')) {
        const feature = this.postBoxesFeatures.find((postBox) => postBox.properties.id === id);
        if (feature) {
          this.openPopup(feature);
        }
      } else {
        (async () => {
          const searchResult = await fetch(`/briefkasten-suche/${id}`).then((response) => response.json());
          const firstResult = searchResult.features[0];
          if (firstResult) {
            if (firstResult.bbox) {
              map.fitBounds(firstResult.bbox);
            } else {
              map.flyTo({
                center: firstResult.center,
                zoom: 15,
              });
            }
          }
        })();
      }
    }
  }

  openPopup(feature) {
    const {
      map,
    } = this;
    if (this.openedPopup) {
      this.openedPopup.remove();
    }
    const coordinates = feature.geometry.coordinates.slice();
    const {
      title,
      street,
      postalCode,
      city,
      stampSale,
    } = feature.properties;
    let html = '';
    if (stampSale) {
      html += '<h3>Briefmarkenverkaufsstelle</h3>';
    } else {
      html += '<h3>Briefkasten</h3>';
    }
    html += '<p>';
    if (title) {
      html += `<strong>${title}</strong><br>`;
    }
    html += `
      ${street}<br>
      ${postalCode} ${city}</p>
      <p><a class="a-button -small -next -outline-on-white" href="https://www.google.com/maps/search/?api=1&query=${coordinates[1]},${coordinates[0]}" target="_blank" rel="noopener noreferrer">Google Maps</a></p>
    `;

    map.flyTo({
      center: coordinates,
      zoom: 15,
    });

    this.openedPopup = new mapboxgl.Popup({
      offset: 5,
      maxWidth: '330px',
    })
      .setLngLat(coordinates)
      .setHTML(html)
      .addTo(map);
  }
}

export default MapPostboxes;
