import mapboxgl from 'mapbox-gl';

class Map {
  constructor(element) {
    const canvasContainer = element.querySelector('.m-map__canvas');
    this.element = element;
    const accessToken = 'pk.eyJ1Ijoid3ctdG9iaWFzd29sZiIsImEiOiJja2N5azd1N2cwN2p0Mnp2cHhtemVnZmdxIn0.v5heUd7FGFS2GG_cQcpyEA';
    const centerLng = 9.1642149;
    const centerLat = 48.4955692;
    const maxBounds = [centerLng - 0.5, centerLat - 0.3, centerLng + 0.5, centerLat + 0.3];
    mapboxgl.accessToken = accessToken;

    function loadMap() {
      this.map = new mapboxgl.Map({
        container: canvasContainer,
        style: 'mapbox://styles/ww-tobiaswolf/ckcyl9i401y1m1imnc51b8yo3', // stylesheet location
        center: [centerLng, centerLat], // starting position [lng, lat]
        zoom: 11.7, // starting zoom
        minZoom: 6,
        maxBounds,
        showZoom: true,
        locale: {
          'AttributionControl.ToggleAttribution': 'Kennzeichnung umschalten',
          'AttributionControl.MapFeedback': 'Rückmeldung zur Karte',
          'FullscreenControl.Enter': 'Vollbildschirm anzeigen',
          'FullscreenControl.Exit': 'Vollbildschirm verlassen',
          'GeolocateControl.FindMyLocation': 'Mein Standort finden',
          'GeolocateControl.LocationNotAvailable': 'Standort nicht verfügbar',
          'LogoControl.Title': 'Mapbox-Logo',
          'NavigationControl.ResetBearing': 'Ausrichtung auf Nordern zurücksetzen',
          'NavigationControl.ZoomIn': 'Vergrößern',
          'NavigationControl.ZoomOut': 'Verkleinern',
          'ScaleControl.Feet': 'ft',
          'ScaleControl.Meters': 'm',
          'ScaleControl.Kilometers': 'km',
          'ScaleControl.Miles': 'mi',
          'ScaleControl.NauticalMiles': 'nm',
        },
      });

      this.map.addControl(new mapboxgl.NavigationControl());
      this.map.addControl(new mapboxgl.GeolocateControl());


      new mapboxgl.Marker({
        color: '#95bf3b',
      })
        .setLngLat([centerLng, centerLat])
        .addTo(this.map);

      this.map.on('load', () => {
        this.map.setLayoutProperty('country-label', 'text-field', [
          'get',
          'name_de',
        ]);
      });

      this.map.on('wheel', (event) => {
        if (event.originalEvent.ctrlKey) {
          return;
        }

        if (event.originalEvent.metaKey) {
          return;
        }

        if (event.originalEvent.altKey) {
          return;
        }

        event.preventDefault();
      });
    }

    loadMap.bind(this)();
  }
}

export default Map;
