const layout = {
  'icon-allow-overlap': true,
  'text-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-size': [
    'interpolate',
    ['linear'],
    ['zoom'],
    13,
    0.6,
    16,
    1,
  ],
  'text-field': [
    'to-string',
    ['get', 'title'],
  ],
  'text-size': [
    'interpolate',
    ['linear'],
    ['zoom'],
    14,
    8,
    22,
    14,
  ],
};

const paint = {
  'icon-halo-width': 2,
  'icon-halo-blur': 2,
  'icon-halo-color': '#D8D8D8',
  'text-translate': [0, 25],
  'text-opacity': [
    'interpolate',
    ['linear'],
    ['zoom'],
    16.5,
    0,
    17,
    1,
  ],
};

export { layout, paint };
