import Map from './components/Map';
import MapPostboxes from './components/MapPostboxes';

const mapPostboxesElement = document.querySelector('.m-map-postboxes');
const mapElement = document.querySelector('.m-map');

if (mapPostboxesElement) {
  new MapPostboxes(mapPostboxesElement);
}
if (mapElement) {
  new Map(mapElement);
}
